/**
 * URL constants. Use this to have a correct overview of all the hardcoded links
 */
export default {
  HELPDESK_FINALIZE_ORDER:
        "https://anybill.freshdesk.com/support/solutions/articles/80001036392-abschluss-der-bestellung",
  RESET_PASSWORD_AD:
        "https://adanybill.b2clogin.com/ad.anybill.de/oauth2/v2.0/authorize?p=B2C_1A_PASSWORDRESET_MAILJET&client_id=f4f77a18-860f-488e-9e15-9c2d8f0d3475&nonce=defaultNonce&scope=openid&response_type=id_token&prompt=login",

  // -- Nav drawer links  --

  DRAWER_POS_CUSTOMER_SUPPORT_LINK:
        "https://anybill.freshdesk.com/support/solutions/80000459085",
  DRAWER_VENDOR_CUSTOMER_SUPPORT_LINK_DE:
        "https://anybill.freshdesk.com/support/solutions/80000459088",
  DRAWER_VENDOR_CUSTOMER_SUPPORT_LINK_EN:
        "https://anybill.freshdesk.com/support/solutions/folders/80000705115",

  // -- Documents Page --
  DOCUMENTS_PERFORMANCE_DRS_BASIC_EN:
        "https://www.anybill.de/contracts/anybill_04_Digital-Receipt-Service-Basic-Performance-Description_EN.pdf",
  DOCUMENTS_PERFORMANCE_DRS_BASIC_DE:
        "https://www.anybill.de/contracts/anybill_04_Digitaler-Beleg-Service-Basis-Leistungsbeschreibung_DE.pdf",
  DOCUMENTS_PARTNER_TERMS_MERCHANT_EN:
        "https://www.anybill.de/contracts/anybill_02_Partner-Terms-Merchant-Partner-SMB_EN.pdf",
  DOCUMENTS_PARTNER_TERMS_MERCHANT_DE:
        "https://www.anybill.de/contracts/anybill_02_Partnerbedingungen-Haendlerpartner-KMU_DE.pdf",
  DOCUMENTS_FEE_SCHEDULE_EN:
        "https://www.anybill.de/contracts/anybill_05_Fee-Schedule-SMB_EN.pdf",
  DOCUMENTS_FEE_SCHEDULE_DE:
        "https://www.anybill.de/contracts/anybill_05_Geb%C3%BChrenordnung-KMU_DE.pdf",
  DOCUMENTS_DATA_PRIVACY_EN: "https://partner.anybill.de/en/privacy",
  DOCUMENTS_DATA_PRIVACY_DE: "https://partner.anybill.de/privacy",
  DOCUMENTS_GLOSSARY_EN:
        "https://www.anybill.de/contracts/anybill_05_Glossary-SMB_EN.pdf",
  DOCUMENTS_GLOSSARY_DE:
        "https://www.anybill.de/contracts/anybill_05_Glossar-KMU_DE.pdf",

  // -- Content Management --
  CONTENT_MANAGEMENT_HELPDESK:
        "https://anybill.freshdesk.com/support/solutions/articles/80001025587-content-management-optionales-add-on-",
};
